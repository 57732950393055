<template>
  <a-tabs
    v-model:activeKey="shaderCart.patActiveKey"
    @tabClick="tabClick"
    class="rays_tuner_shader_mycart_pattab"
  >
    <a-tab-pane v-for="item in patPkgs" :key="item.key">
      <template #tab>
        <span class="tab_title">
          {{ item.tab }}
          <a-badge
            class="tab_badge"
            :count="item.count"
            :number-style="{
              backgroundColor: 'rgba(60, 60, 60, .1)',
              color: '#000',
            }"
          />
        </span>
      </template>
    </a-tab-pane>
  </a-tabs>
  <div
    class="tab_body_con"
    :style="{
      height: shaderCart.patsOpen
        ? 'calc( 100% - 446px)'
        : 'calc( 100% - 117px)',
    }"
    id="shader_mycart_pat_shaderlist_scroll"
  >
    <div
      v-for="shader in (patPkgs[shaderCart.patActiveKey] || {}).shaders"
      :key="shader.key"
      class="screen_con"
    >
      <a-checkbox
        class="screen_checkbox"
        v-model:checked="shader.checked"
        @change="itemClick"
      >
      </a-checkbox>
      <a-image
        :width="72"
        :height="48"
        :src="shader.url"
        :preview="false"
        :fallback="imgSvg"
      />
      <div class="screen_name">{{ shader.screenName }}</div>
      <div class="screen_info">
        Time:{{ shader.time }}&nbsp;&nbsp;&nbsp;&nbsp;Error:{{ shader.error }}
      </div>
    </div>
  </div>
  <div class="bottom_con" :class="{ open: shaderCart.patsOpen }">
    <div class="tool_bar">
      <div class="open_btn" @click="openPats">
        <svg-icon
          icon-class="chevron-up_double"
          class="cart_icon"
          :class="{ open: shaderCart.patsOpen }"
        />
        检测PAT
        <a-badge
          class="pat_badge"
          :count="shaderCart.selectedPats.length"
          :showZero="true"
          :number-style="{
            backgroundColor: 'rgba(60, 60, 60, .1)',
            color: '#000',
          }"
        />
      </div>
      <a-select
        v-model:value="shaderCart.selectedPats"
        mode="multiple"
        style="width: 320px"
        placeholder="请选择用于检测的PAT(可多选)"
        :options="shaderCart.patOptions"
        @change="patHandleChange"
        :dropdownStyle="patSelectDropStyle"
        dropdownClassName="shader_mycart_pat_select_drop"
        :maxTagCount="0"
        notFoundContent="没有搜索到相应PAT"
        maxTagPlaceholder="请选择用于检测的PAT(可多选)"
        @dropdownVisibleChange="patDropDown"
        :open="selectOpen"
      >
        <template #dropdownRender="{ menuNode: menu }">
          <v-nodes :vnodes="menu" />
          <a-divider style="margin: 4px 0" />
          <div class="add_item" @mousedown.prevent @click="toPatList">
            <svg-icon icon-class="newpage" class="add_icon" />
            上传PAT
          </div>
        </template>
      </a-select>
      <a-button
        class="bottom_submit"
        @click="submitClick"
        :loading="submitLoading"
        >提交任务</a-button
      >
    </div>
    <div
      class="pat_body"
      id="shader_mycart_pat_patlist_scroll"
      @click="patsClick"
    >
      <div
        v-for="(pat, index) in shaderCart.selectedPats"
        :key="index"
        class="pat_item_con"
      >
        <div class="title">{{ pat }}</div>
        <svg-icon icon-class="delete" class="del_icon" :data-pat="index" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  computed,
  nextTick,
  watch,
} from 'vue';
import store from '@/vuex/store';
import { setShaderCartInfo, setPat } from '@/models/tunerStorage';
import PerfectScrollbar from 'perfect-scrollbar';
import router from '@/routes';
import { message } from 'ant-design-vue';
import api from '@/api/shader-detect';

export default defineComponent({
  name: 'CartPatList',
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  emits: ['hideCart'],
  setup(props, context) {
    let shaderBodyDom = null;
    let shaderBodyPs = null;
    let patBodyDom = null;
    let patBodyPs = null;
    const imgSvg =
      'data:image/svg+xml;utf8,<svg width="72" height="47" viewBox="-13 -13 50 50" fill="gray" ' +
      'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
      'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
      '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
      '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
      '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';
    const shaderCart = computed(() => store.state.imageDetect.shaderCart || {});
    const allGoods = computed(() => store.state.imageDetect.allGoods || {});
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;

    watch(allGoods, () => {
      console.log('allGoods watch');
      initPkgTabs();
    });

    const patPkgs = ref([]);

    const initPkgTabs = () => {
      console.log('initPkgTabs');
      const _pkgs = [];
      const _pkgk = {};
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      const _goods = shaderCart.value.selectedGoods[_phone] || [];
      for (let i = 0; i < _goods.length; i++) {
        const _patnamearr = _goods[i].pat_name.split('.');
        const _pkgname =
          _patnamearr[_patnamearr.length - 4] +
          '.' +
          _patnamearr[_patnamearr.length - 3];
        if (!_pkgk[_pkgname] && _pkgk[_pkgname] !== 0) {
          // console.log(_pkgname,_pkgk[_pkgname], _pkgs);
          const _tmpk = _pkgs.length;
          _pkgk[_pkgname] = _tmpk;
          _pkgs.push({
            key: _pkgk[_pkgname],
            tab: _pkgname,
            count: 0,
            shaders: [],
          });
        }
        const _shaders = _pkgs[_pkgk[_pkgname]].shaders;
        _goods[i].key = i;
        _shaders.push(_goods[i]);
        _pkgs[_pkgk[_pkgname]].count = _shaders.length;
      }
      console.log('_pkgk', _pkgs);
      patPkgs.value = _pkgs;
      if (
        _pkgs.length > 0 &&
        _pkgs.length - 1 < shaderCart.value.patActiveKey
      ) {
        console.log(
          '_pkgs.length-1',
          _pkgs.length - 1,
          shaderCart.value.patActiveKey
        );
        shaderCart.value.patActiveKey = _pkgs.length - 1;
        setShaderCartInfo(shaderCart.value);
      } else if (_pkgs.length == 0) {
        shaderCart.value.cartStep = 1;
        shaderCart.value.selectedPats = [];
        setShaderCartInfo(shaderCart.value);
      }
      nextTick(() => {
        if (shaderBodyPs) {
          shaderBodyPs.update();
        }
      });
    };

    const tabClick = (val) => {
      console.log('tabClick', val);
      shaderCart.value.patActiveKey = val;
      setShaderCartInfo(shaderCart.value);
      nextTick(() => {
        if (shaderBodyPs) {
          shaderBodyPs.update();
        }
      });
    };

    const itemClick = () => {
      // console.log('itemClick',val);
      setShaderCartInfo(shaderCart.value);
    };

    const openPats = () => {
      shaderCart.value.patsOpen = shaderCart.value.patsOpen ? false : true;
      setShaderCartInfo(shaderCart.value);
    };

    // let patSelectDropDom = null;
    const patSelectDropStyle = ref({});
    const patDropDown = (open) => {
      console.log('patDropDown',open);
      selectOpen.value = open;
      // nextTick(() => {
      //   if (!patSelectDropDom) {
      //     patSelectDropDom = document.querySelector(
      //       '.shader_mycart_pat_select_drop'
      //     );
      //   }
      //   patSelectDropStyle.value = {};
      //   if (patSelectDropDom && open && !shaderCart.value.patsOpen) {
      //     const _drawer = document.querySelector(
      //       '.rays_tuner_shader_mycart_drawer'
      //     );
      //     let _height = patSelectDropDom.scrollHeight;
      //     let _count = shaderCart.value.patOptions.length;
      //     // _count =
      //     _height = 32 * _count + 67;
      //     console.log(patSelectDropDom.scrollHeight, _height);
      //     patSelectDropStyle.value = {
      //       top: _drawer.scrollHeight - 56 - _height + 'px',
      //     };
      //   }
      // },100);
    };
    const selectOpen = ref(false);
    const patHandleChange = (val) => {
      // console.log('patHandleChange',val);
      shaderCart.value.selectedPats = val;
      setShaderCartInfo(shaderCart.value);
      nextTick(() => {
        if (patBodyPs) {
          patBodyPs.update();
        }
      });
    };

    const toPatList = () => {
      // console.log('toPatList');
      selectOpen.value = false;
      setPat({});
      router.push({
        path: '/mainhome/simplifyhome/pat',
      });
      context.emit('hideCart');
    };

    const patsClick = (event) => {
      console.log('patsClick', event);
      let _dom = event.target;
      while (_dom) {
        if (
          _dom.className &&
          _dom.className.baseVal &&
          _dom.className.baseVal == 'del_icon'
        ) {
          const _patIndex = parseInt(_dom.dataset.pat);
          shaderCart.value.selectedPats.splice(_patIndex, 1);
          nextTick(() => {
            if (patBodyPs) {
              patBodyPs.update();
            }
          });
          break;
        }
        _dom = _dom.parentNode;
      }
    };

    const delCarts = async (_cartids = []) => {
      if (_cartids.length == 0) {
        return;
      }
      const params = {
        id_arr_str: _cartids.join(','),
      };
      const res = await api.cartDel(params, { noMessage: true });
      submitLoading.value = false;
      if (!res) return;
      if (res.code == 0) {
        store.commit('setShaderCartUpdate', new Date().getTime());
        message.success('提交购物车成功！');
      } else {
        message.error('删除已提交内容失败！');
      }
    };
    const addErrorDetectionTask = async (replaceGames, _cartids) => {
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      const params = {
        user: $_email,
        user_id: $_user.uid,
        device_type: _phone,
        replace_games: replaceGames,
      };
      const res = await api.addErrorDetectionTask(params, { noMessage: true });
      if (!res) {
        submitLoading.value = false;
        return;
      }
      if (res.code == 0) {
        delCarts(_cartids);
      } else {
        submitLoading.value = false;
        message.error('提交购物车失败：', res.detail);
      }
    };

    const submitLoading = ref(false);

    const submitClick = () => {
      submitLoading.value = true;
      const _pats = JSON.parse(JSON.stringify(shaderCart.value.selectedPats));
      const _replace_games = [];
      const _cartids = [];
      let _nopat = false;
      for (let i = 0; i < patPkgs.value.length; i++) {
        const _rep_shaders = [];
        const _shaders = patPkgs.value[i].shaders || [];
        for (let sindex = 0; sindex < _shaders.length; sindex++) {
          if (_shaders[sindex].checked) {
            _rep_shaders.push({
              pat: _shaders[sindex].pat_name,
              pid: parseInt(_shaders[sindex].shaderid),
              variant: _shaders[sindex].screenName,
              simp_task_id: _shaders[sindex].simp_task_id,
              md5: _shaders[sindex].md5,
            });

            _cartids.push(_shaders[sindex].id);
          }
        }
        const _rep_pat = [];
        for (let pindex = 0; pindex < _pats.length; pindex++) {
          const _patnamearr = _pats[pindex].split('.');
          const _pkgname =
            _patnamearr[_patnamearr.length - 4] +
            '.' +
            _patnamearr[_patnamearr.length - 3];
          if (_pkgname == patPkgs.value[i].tab) {
            _rep_pat.push(_pats.splice(pindex, 1)[0] || '');
            pindex--;
          }
        }
        if (_rep_pat.length > 0 && _rep_shaders.length > 0) {
          _replace_games.push({
            game: patPkgs.value[i].tab,
            replace_pats: _rep_pat,
            replace_shaders: _rep_shaders,
          });
        } else if (_rep_pat.length == 0) {
          _nopat = true;
        }
      }

      if (_replace_games.length > 0) {
        addErrorDetectionTask(_replace_games, _cartids);
      } else {
        if (_nopat) {
          message.warning('没有匹配的pat！');
        } else {
          message.warning('没有选中的shader！');
        }
        submitLoading.value = false;
      }
      console.log(
        'submitClick',
        _replace_games,
        _pats,
        patPkgs.value[shaderCart.value.patActiveKey] || {}
      );
    };

    onMounted(() => {
      console.log('CartPatList onMounted');
      // 初始化Shader和pat列表scroll
      shaderBodyDom = document.querySelector(
        '#shader_mycart_pat_shaderlist_scroll'
      );
      shaderBodyPs = new PerfectScrollbar(shaderBodyDom);

      patBodyDom = document.querySelector('#shader_mycart_pat_patlist_scroll');
      patBodyPs = new PerfectScrollbar(patBodyDom);

      initPkgTabs();
    });
    onBeforeUnmount(() => {
      if (shaderBodyPs) {
        shaderBodyPs.destroy();
        shaderBodyPs = null;
      }
      shaderBodyDom = null;

      if (patBodyPs) {
        patBodyPs.destroy();
        patBodyPs = null;
      }
      patBodyDom = null;
    });

    return {
      patPkgs,
      imgSvg,
      shaderCart,
      selectOpen,
      submitLoading,
      patSelectDropStyle,
      tabClick,
      itemClick,
      openPats,
      patDropDown,
      patHandleChange,
      toPatList,
      patsClick,
      submitClick,
    };
  },
});
</script>
<style lang="scss" scoped>
.rays_tuner_shader_mycart_pattab {
  .tab_title {
    height: 22px;
  }
  .tab_badge {
    margin-left: 5px;
  }
  .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: -5px;
  }
}
.tab_body_con {
  position: relative;
  height: calc(100% - 117px);
  width: 100%;
  background-color: #fafafa;
  padding: 20px 16px 20px 16px;
  .screen_con {
    height: 80px;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    background-color: #fff;
  }
  .screen_name {
    width: 700px;
    height: 22px;
    // display: inline-block;
    padding-left: 16px;
    font-size: 14px;
    color: #3c3c3c;
    line-height: 22px;
    float: right;
  }
  .screen_info {
    width: 700px;
    height: 20px;
    // display: inline-block;
    padding-left: 16px;
    font-size: 12px;
    color: #aaa;
    line-height: 20px;
    float: right;
    margin-top: -20px;
    // position: absolute;
    // left: 161px;
    // margin-top: 28px;
  }
}
.bottom_con {
  position: absolute;
  height: 72px;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #d8d8d8;
  background-color: #fff;
  &.open {
    height: 401px;
    .pat_body {
      height: 329px;
      padding-top: 8px;
    }
  }
  .tool_bar {
    height: 71px;
    padding: 21px 40px 20px 40px;
  }
  .open_btn {
    display: inline-block;
    height: 100%;
    width: 135px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #222;
    line-height: 30px;
    cursor: pointer;
    .ant-badge {
      margin-left: 6px;
      vertical-align: -4px;
    }
  }
  .cart_icon {
    width: 16px;
    height: 16px;
    color: #231f20;
    margin-right: 6px;
    vertical-align: -3px;
    transition: transform 0.24s;
    &.open {
      transform: rotate(180deg);
    }
  }
  .ant-select {
    margin-left: 230px;
  }
  .bottom_submit {
    margin-left: 24px;
    background-color: #604bdc;
    border-color: #604bdc;
    color: #fff;
    border-radius: 4px;
    &:focus,
    &:hover,
    &:active {
      background-color: #7a67e4;
      border-color: #7a67e4;
    }
  }
  .pat_body {
    position: relative;
    width: 100%;
    height: 0;
  }
  .pat_item_con {
    width: 896px;
    height: 56px;
    padding: 16px 40px 16px 40px;
    border-top: 1px solid #f0f0f0;
    .title {
      display: inline-block;
      color: 3c3c3c;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
  .del_icon {
    width: 24px;
    height: 24px;
    float: right;
    color: #d8d8d8;
    cursor: pointer;
    &:hover {
      color: #f41b15;
    }
  }
}

.shader_mycart_pat_select_drop {
  .add_item {
    height: 50px;
    padding: 16px 8px 16px 12px;
    cursor: pointer;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #604bdc;
    line-height: 18px;
  }
  .add_icon {
    width: 16px;
    height: 16px;
    vertical-align: -3px;
  }
}
</style>
<style lang="scss">
.rays_tuner_shader_mycart_pattab {
  .ant-tabs-nav .ant-tabs-tab {
    color: #3c3c3c;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 500;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    font-weight: 500;
    color: #000;
  }
  .ant-tabs-ink-bar {
    background-color: #604bdc;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
}
#shader_mycart_pat_shaderlist_scroll {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #604bdc;
    border-color: #604bdc;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #604bdc;
  }
  .ant-checkbox-checked::after {
    border-color: #604bdc;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #604bdc;
  }
  .ant-image {
    margin-left: 16px;
  }
  img {
    border-radius: 4px;
    background-color: #222;
  }
}
</style>
