<template>
  <a-tooltip>
    <template #title>购物车</template>
    <div class="rays_tuner_mycart_top_icon" @click="showCart(true)">
      <a-badge :count="cartCount">
        <svg-icon icon-class="box" class="top_icon cart" />
      </a-badge>
    </div>
  </a-tooltip>
  <a-drawer
    wrapClassName="rays_tuner_shader_mycart_drawer"
    placement="right"
    :closable="false"
    width="896"
    v-model:visible="cartVisible"
  >
    <div class="drawer_head">
      <div class="drawer_title" v-if="shaderCart.cartStep == 1">
        <svg-icon icon-class="box" class="cart_icon" />
        待检测Shader
      </div>
      <div
        class="drawer_title back"
        v-if="shaderCart.cartStep == 2"
        @click="transPage(1)"
      >
        <svg-icon icon-class="chevron-left" class="cart_icon" />
        返回上一步
      </div>
      <div class="close" @click="showCart(false)">
        <svg-icon icon-class="close" class="close_icon" />
        关闭
      </div>
    </div>
    <div class="drawer_body">
      <CartShaderList @next="transPage(2)" v-if="shaderCart.cartStep == 1" />
      <CartPatList
        @hideCart="showCart(false)"
        v-if="shaderCart.cartStep == 2"
      />
    </div>
  </a-drawer>
</template>
<script>
import { defineComponent, ref, computed, watch } from 'vue';
import store from '@/vuex/store';
import CartShaderList from '@/views/shader-detect/CartShaderList.vue';
import CartPatList from '@/views/shader-detect/CartPatList.vue';
import { setShaderCartInfo } from '@/models/tunerStorage';
import { message } from 'ant-design-vue';
import api from '@/api/shader-detect';
import patApi from '@/api/pat-manager';
import { getBaseUrl } from '@/api/service';
const engineurl = getBaseUrl('engine');

export default defineComponent({
  name: 'MyCart',
  components: {
    CartShaderList,
    CartPatList,
  },
  setup() {
    const cartCount = ref(0);
    const cartVisible = ref(false);
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const shaderCart = computed(() => store.state.imageDetect.shaderCart || {});
    const updateShaderCart = computed(
      () => store.state.imageDetect.updateShaderCart || 0
    ); //setShaderCartUpdate

    watch(updateShaderCart, () => {
      console.log('updateShaderCart watch');
      getUserCart();
    });
    const showCart = (show) => {
      cartVisible.value = show || false;
      if (show) {
        getUserCart();
        getPatList();
      }
    };

    // const typetest = ['HONOR:NTH','HONOR:YOK-AN10']
    // const pkgtest = ['tmgp.sgame.', 'tmgp.pubgmhd.', 'miHoYo.Yuanshen.'];
    // const testarr = []
    // for (let i = 0; i < 10; i++) {
    //     const _patname = 'com.tencent.' + pkgtest[i - (Math.floor(i/3) * 3)] + i * 5 +'.pat';
    //     for (let k = 0; k < i*3; k++) {
    //         testarr.push({
    //             "id": i*1000 + k,
    //             "task_id": "0",
    //             "user_id": "0",
    //             params:JSON.stringify({
    //                 device_type: typetest[i - (Math.floor(i/2) * 2)],
    //                 pat: _patname,
    //                 md5: 'ssssssss'+ i + k,
    //                 simp_task_id:'2022010733' + i +'-'+ k,
    //                 var_shader_name: `_${i}_${k}m`,
    //                 highlight:'',
    //                 time: '-0.4234234234324',
    //                 error: '0.00000335',
    //             })
    //         })
    //     }
    // }

    const initPhoneTabs = (arr = []) => {
      // arr = testarr;
      console.log('initPhoneTabs', arr, arr.length);
      cartCount.value = arr.length;
      const _tabkeys = {};
      const _tabgoods = [];
      for (let i = 0; i < arr.length; i++) {
        const _params = JSON.parse(arr[i].params);
        if (
          !_tabkeys[_params.device_type] &&
          _tabkeys[_params.device_type] !== 0
        ) {
          _tabkeys[_params.device_type] = _tabgoods.length;
          _tabgoods.push({
            key: _tabkeys[_params.device_type],
            tab: _params.device_type,
            patKeys: {},
            pats: [],
            count: 0,
          });
        }
        const _tab = _tabgoods[_tabkeys[_params.device_type]];
        if (!_tab.patKeys[_params.pat] && _tab.patKeys[_params.pat] !== 0) {
          _tab.patKeys[_params.pat] = _tab.pats.length;
          _tab.pats.push({
            key: _tab.patKeys[_params.pat],
            pat_name: _params.pat,
            shaders: [],
            checked: false,
            indeterminate: false,
          });
        }
        const _shaders = _tab.pats[_tab.patKeys[_params.pat]].shaders;
        _shaders.push({
          key: _shaders.length,
          id: arr[i].id,
          screenName: _params.var_shader_name,
          shaderid: _params.shader_id,
          simp_task_id: _params.simp_task_id,
          md5: _params.md5,
          pat_name: _params.pat,
          url:
            engineurl +
            '/api/sopt/simplifier/getHighlightByFilename?filename=' +
            _params.highlight +
            '&pat=' +
            _params.pat +
            '&user=' +
            $_email +
            '&pid=' +
            _params.shader_id,
          time: _params.time,
          error: _params.error,
          checked: false,
        });
        _tab.count++;
      }
      console.log('initPhoneTabs res', _tabgoods);
      return _tabgoods;
    };

    const allGoods = computed(() => store.state.imageDetect.allGoods || {});
    watch(allGoods, () => {
      console.log('MyCart allGoods watch');
      checkSelectsGoods();
    });

    //自动清理选中商品中不存在商品的方法
    const checkSelectsGoods = () => {
      const _tab = allGoods.value[shaderCart.value.shaderActiveKey] || {};
      const _pats = JSON.parse(JSON.stringify(_tab.pats || []));
      const _goods = shaderCart.value.selectedGoods[_tab.tab] || [];

      for (let i = 0; i < _goods.length; i++) {
        let _hasGood = false;
        goodsLoop: for (let pindex = 0; pindex < _pats.length; pindex++) {
          if (_pats[pindex].pat_name == _goods[i].pat_name) {
            const _shader = _pats[pindex].shaders;
            for (let sindex = 0; sindex < _shader.length; sindex++) {
              if (
                _shader[sindex].screenName == _goods[i].screenName &&
                _shader[sindex].md5 == _goods[i].md5
              ) {
                _hasGood = true;
                _shader.splice(sindex, 1);
                break goodsLoop;
              }
            }
          }
        }
        if (!_hasGood) {
          _goods.splice(i, 1);
          i--;
        }
      }
      setShaderCartInfo(shaderCart.value);
    };

    const getUserCart = async () => {
      // console.log('getUserCart');
      cartCount.value = 0;
      const params = {
        user_id: $_user.uid,
      };
      const res = await api.cartGets(params);
      if (!res) return;
      if (res.code == 0) {
        const _cartlist = res.data || [];
        store.commit('setShaderCartOriginalGoods', _cartlist);
        store.commit('setShaderCartAllGoods', initPhoneTabs(_cartlist));
      } else {
        message.error('获取用户购物车失败！');
      }
    };

    const getPatList = async () => {
      const params1 = {
        user: $_email,
        pattern: '.*',
      };
      const countRes = await patApi.getPatCount(params1);
      if (!countRes) return;
      if (countRes.code != 0) {
        message.error('获取PAT列表总数失败!');
        return;
      }
      const _total = countRes.count || 0;
      if (_total > 0) {
        const params2 = {
          user: $_email,
          pattern: '.*',
          offset: 0,
          count: _total,
        };
        const listRes = await patApi.getPatList(params2);
        if (!listRes) return;
        if (listRes.code != 0) {
          message.error('获取PAT列表失败!');
          return;
        }
        let _list = JSON.parse(listRes.result_list);
        let _opt = [];
        for (let i = 0; i < _list.length; i++) {
          _opt.push({
            value: _list[i].pat_name,
            label: _list[i].pat_name,
          });
        }
        // for (let i = 10; i < 30; i++) {
        //     _opt.push({
        //         value: `com.miHoYo.Yuanshen.s${i}.pat`,
        //         label: `com.miHoYo.Yuanshen.s${i}.pat`
        //     });
        // }
        shaderCart.value.patOptions = _opt;
        setShaderCartInfo(shaderCart.value);
      }
    };

    const transPage = (val) => {
      shaderCart.value.cartStep = val;
      setShaderCartInfo(shaderCart.value);
    };
    getUserCart();
    return {
      cartCount,
      cartVisible,
      shaderCart,
      showCart,
      transPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.rays_tuner_mycart_top_icon {
  width: 48px;
  height: 47px;
  padding-left: 12px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  .top_icon {
    width: 24px;
    height: 24px;
    margin-top: -2px;
    margin-right: 5px;
    color: #aaa;
    font-size: 20px;
  }
  &:hover .top_icon {
    color: #604bdc;
  }
}
.rays_tuner_shader_mycart_drawer {
  .drawer_head {
    width: 100%;
    height: 72px;
    padding: 24px 40px 24px 40px;
    border-bottom: 1px solid #f0f0f0;
    .close {
      float: right;
      color: #aaa;
      cursor: pointer;
    }
    .close_icon {
      height: 20px;
      width: 20px;
      vertical-align: bottom;
    }
  }
  .drawer_title {
    height: 24px;
    display: inline-block;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: LEFT;
    color: #222;
    line-height: 24px;
    .cart_icon {
      width: 24px;
      height: 24px;
      color: #231f20;
      vertical-align: bottom;
    }
  }
  .back {
    cursor: pointer;
  }
  .drawer_body {
    height: calc(100% - 72px);
  }
}
</style>
<style lang="scss">
.rays_tuner_mycart_top_icon {
  .ant-badge-zoom-leave {
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
  }
}
.rays_tuner_shader_mycart_drawer {
  .ant-drawer-body {
    height: 100%;
    padding: 0;
  }
}
</style>
