<template>
  <a-tabs
    v-model:activeKey="shaderCart.shaderActiveKey"
    @tabClick="tabClick"
    class="rays_tuner_shader_mycart_shadertab"
  >
    <a-tab-pane v-for="item in allGoods" :key="item.key">
      <template #tab>
        <span class="tab_title">
          {{ item.tab }}
          <a-badge
            class="tab_badge"
            :count="item.count"
            :number-style="{
              backgroundColor: 'rgba(60, 60, 60, .1)',
              color: '#000',
            }"
          />
        </span>
      </template>
    </a-tab-pane>
  </a-tabs>
  <div class="tab_body_con" id="shader_mycart_shaderlist_scroll">
    <a-collapse expand-icon-position="right" @change="collapseChange">
      <template #expandIcon="{ isActive }">
        <span
          ><svg-icon
            icon-class="chevron-down"
            class="panel_icon"
            :class="{ turnup: isActive }"
        /></span>
      </template>
      <a-collapse-panel
        v-for="pat in goods"
        :key="pat.key"
        :header="pat.pat_name"
      >
        <div v-for="shader in pat.shaders" :key="shader.key" class="screen_con">
          <a-checkbox
            class="screen_checkbox"
            v-model:checked="shader.checked"
            @change="itemClick"
          >
          </a-checkbox>
          <a-image
            :width="72"
            :height="48"
            :src="shader.url"
            :preview="false"
            :fallback="imgSvg"
          />
          <div class="screen_name">
            {{ shader.simp_task_id }} | {{ shader.shaderid
            }}{{ shader.screenName }}
          </div>
          <div class="screen_info">
            Time:{{ shader.time }}&nbsp;&nbsp;&nbsp;&nbsp;Error:{{
              shader.error
            }}
          </div>
        </div>
        <template #extra>
          <a-checkbox
            class="panel_checkbox"
            v-model:checked="pat.checked"
            :indeterminate="pat.indeterminate"
            @change="itemClick(pat.key)"
            @click.stop
          >
          </a-checkbox>
          <a-badge
            class="panel_badge"
            :count="pat.shaders.length"
            :number-style="{
              backgroundColor: 'rgba(60, 60, 60, .1)',
              color: '#000',
            }"
          />
        </template>
      </a-collapse-panel>
    </a-collapse>
  </div>
  <div class="bottom_con">
    <a-checkbox
      class="mycart_shaderlist_bottom_check"
      v-model:checked="
        shaderCart.shaderSelectAll[
          (allGoods[shaderCart.shaderActiveKey] || {}).tab || ''
        ]
      "
      :indeterminate="
        shaderCart.shaderIndeterminate[
          (allGoods[shaderCart.shaderActiveKey] || {}).tab || ''
        ] || false
      "
      @change="itemClick(-1)"
    >
      全选
    </a-checkbox>
    <div class="bottom_count">
      已选
      {{
        (
          shaderCart.selectedGoods[
            (allGoods[shaderCart.shaderActiveKey] || {}).tab || ''
          ] || []
        ).length
      }}
      项
    </div>
    <a-button danger class="bottom_del" @click="delClick">删除</a-button>
    <a-button class="bottom_next" @click="nextClick">下一步</a-button>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  createVNode,
  ref,
  watch,
  nextTick,
} from 'vue';
import store from '@/vuex/store';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { setShaderCartInfo } from '@/models/tunerStorage';
import api from '@/api/shader-detect';

export default defineComponent({
  name: 'CartShaderList',
  emits: ['next'],
  setup(props, context) {
    let shaderBodyDom = null;
    let shaderBodyPs = null;
    const imgSvg =
      'data:image/svg+xml;utf8,<svg width="72" height="47" viewBox="-13 -13 50 50" fill="gray" ' +
      'xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" ' +
      'd="M19 17.834C19 18.478 18.552 19 18 19H6L13.566 12.183C13.812 11.938 14.258 11.939 14.499 ' +
      '12.182L19 16.66V17.834ZM8 7C8.828 7 9.5 7.672 9.5 8.5C9.5 9.328 8.828 10 8 10C7.172 10 6.5 9.328 ' +
      '6.5 8.5C6.5 7.672 7.172 7 8 7ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 ' +
      '21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"/></svg>';
    const shaderCart = computed(() => store.state.imageDetect.shaderCart || {});
    const allGoods = computed(() => store.state.imageDetect.allGoods || {});
    // const $_user = store.state.user.userinfo;
    // const $_email = $_user.email;

    watch(allGoods, () => {
      // console.log('allGoods watch', arr);
      initSelected();
    });

    const goods = ref([]);

    const tabClick = (val) => {
      console.log('tabClick', val);
      shaderCart.value.shaderActiveKey = val;
      initSelected();
      setShaderCartInfo(shaderCart.value);
      nextTick(() => {
        if (shaderBodyPs) {
          shaderBodyPs.update();
        }
      });
    };

    const scanGoods = (val) => {
      console.log(goods.value, val, typeof val);
      const _sGoods = [];
      let _countAll = 0;
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      // console.log(shaderCart.value.shaderSelectAll[_phone]);
      for (let i = 0; i < goods.value.length; i++) {
        const _pat = goods.value[i];
        if (typeof val == 'object') {
          let _checkCount = 0;
          for (let sub = 0; sub < _pat.shaders.length; sub++) {
            if (_pat.shaders[sub].checked) {
              _checkCount++;
              _sGoods.push(_pat.shaders[sub]);
            }
          }
          // console.log(_checkCount);
          if (_checkCount > 0 && _checkCount < _pat.shaders.length) {
            _pat.indeterminate = true;
            _pat.checked = false;
          } else {
            _pat.indeterminate = false;
            if (_checkCount == _pat.shaders.length && _checkCount > 0) {
              _pat.checked = true;
            } else {
              _pat.checked = false;
            }
          }
        } else if (val != -1) {
          _pat.indeterminate = false;
          // console.log(_pat.checked);
          for (let sub = 0; sub < _pat.shaders.length; sub++) {
            _pat.shaders[sub].checked = _pat.checked;
            if (_pat.checked) {
              _sGoods.push(_pat.shaders[sub]);
            }
          }
        } else {
          // console.log('shaderSelectAll',shaderCart.value.shaderSelectAll);
          _pat.checked = shaderCart.value.shaderSelectAll[_phone];
          for (let sub = 0; sub < _pat.shaders.length; sub++) {
            _pat.shaders[sub].checked =
              shaderCart.value.shaderSelectAll[_phone];
            if (shaderCart.value.shaderSelectAll[_phone]) {
              _sGoods.push(_pat.shaders[sub]);
            }
          }
        }
        _countAll += _pat.shaders.length;
      }
      // console.log('_sGoods', _sGoods)
      shaderCart.value.selectedGoods[_phone] = _sGoods;
      if (val != -1) {
        const _selectAllCount = shaderCart.value.selectedGoods[_phone].length;
        if (_selectAllCount > 0 && _selectAllCount < _countAll) {
          shaderCart.value.shaderIndeterminate[_phone] = true;
          shaderCart.value.shaderSelectAll[_phone] = false;
        } else {
          shaderCart.value.shaderIndeterminate[_phone] = false;
          if (_selectAllCount == _countAll && _selectAllCount > 0) {
            shaderCart.value.shaderSelectAll[_phone] = true;
          } else {
            shaderCart.value.shaderSelectAll[_phone] = false;
          }
        }
      } else if (shaderCart.value.shaderSelectAll[_phone]) {
        shaderCart.value.shaderIndeterminate[_phone] = false;
      }
      setShaderCartInfo(shaderCart.value);
    };

    const collapseChange = () => {
      // console.log('collapseChange');
      nextTick(() => {
        if (shaderBodyPs) {
          shaderBodyPs.update();
        }
      });
    };

    const itemClick = (val) => {
      // console.log('itemClick',val);
      scanGoods(val);
    };

    const delUserCart = async () => {
      const _selects =
        shaderCart.value.selectedGoods[
          (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || ''
        ];
      let _id_arr = '';
      for (let i = 0; i < _selects.length; i++) {
        if (i) {
          _id_arr += ',';
        }
        _id_arr += _selects[i].id;
      }
      // console.log('delUserCart', _id_arr);
      const params = {
        id_arr_str: _id_arr,
      };
      const res = await api.cartDel(params);
      if (!res) return;
      if (res.code == 0) {
        message.success('删除用户购物车数据成功！');
        store.commit('setShaderCartUpdate', new Date().getTime());
      } else {
        message.error('删除用户购物车数据失败！');
      }
    };

    const delConfirm = () => {
      Modal.confirm({
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要删除选中shader变体吗？',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve) => {
            delUserCart();
            resolve();
          });
        },
      });
    };

    const delClick = () => {
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      if (shaderCart.value.selectedGoods[_phone].length == 0) {
        message.warning('请先选中shader变体！');
        return;
      }
      delConfirm();
    };

    const nextClick = () => {
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      if (
        !shaderCart.value.selectedGoods[_phone] ||
        shaderCart.value.selectedGoods[_phone].length == 0
      ) {
        message.warning('请先选中shader变体！');
        return;
      }
      context.emit('next');
    };

    const initSelected = () => {
      goods.value =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).pats || [];
      console.log('initSelected', goods.value, allGoods.value);
      const _phone =
        (allGoods.value[shaderCart.value.shaderActiveKey] || {}).tab || '';
      const _tmpSelects = JSON.parse(
        JSON.stringify(shaderCart.value.selectedGoods[_phone] || [])
      );
      for (let i = 0; i < goods.value.length && _tmpSelects.length > 0; i++) {
        const _pat = goods.value[i];
        let _inPatSelects = [];
        for (
          let sindex = 0;
          sindex < _pat.shaders.length - _inPatSelects.length &&
          sindex < _tmpSelects.length;
          sindex++
        ) {
          if (_tmpSelects[sindex].pat_name == _pat.pat_name) {
            _inPatSelects.push(_tmpSelects.splice(sindex, 1)[0]);
            sindex--;
          }
          // console.log('_tmpSelects',_tmpSelects, _inPatSelects.length, _pat.shaders.length ,JSON.stringify(_inPatSelects[_inPatSelects.length-1]));
        }
        if (_inPatSelects.length > 0) {
          if (_inPatSelects.length == _pat.shaders.length) {
            _pat.checked = true;
            _pat.indeterminate = false;
          } else {
            _pat.checked = false;
            _pat.indeterminate = true;
          }
        }
        for (
          let sub = 0;
          sub < _pat.shaders.length && _inPatSelects.length > 0;
          sub++
        ) {
          for (let ipindex = 0; ipindex < _inPatSelects.length; ipindex++) {
            // console.log('_inPatSelects',_inPatSelects, _pat.shaders[sub].screenName, _inPatSelects[ipindex].screenName);
            if (
              _inPatSelects[ipindex].screenName == _pat.shaders[sub].screenName
            ) {
              _pat.shaders[sub].checked = true;
              _inPatSelects.splice(ipindex, 1);
              break;
            }
          }
        }
      }
    };

    onMounted(() => {
      console.log('CartShaderList onMounted');
      // 初始化Shader列表scroll
      shaderBodyDom = document.querySelector(
        '#shader_mycart_shaderlist_scroll'
      );
      shaderBodyPs = new PerfectScrollbar(shaderBodyDom);
      initSelected();
    });
    onBeforeUnmount(() => {
      if (shaderBodyPs) {
        shaderBodyPs.destroy();
        shaderBodyPs = null;
      }
      shaderBodyDom = null;
    });
    return {
      imgSvg,
      shaderCart,
      allGoods,
      goods,
      tabClick,
      itemClick,
      collapseChange,
      delClick,
      nextClick,
    };
  },
});
</script>
<style lang="scss" scoped>
.rays_tuner_shader_mycart_shadertab {
  height: 45px;
  .tab_title {
    height: 22px;
  }
  .tab_badge {
    margin-left: 5px;
  }
  .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: -5px;
  }
}
.tab_body_con {
  position: relative;
  height: calc(100% - 117px);
  width: 100%;
  background-color: #fafafa;
  .ant-collapse {
    padding: 20px 16px 16px 16px;
  }
  .panel_icon {
    width: 24px;
    height: 24px;
    color: #aaa;
    &.turnup {
      transform: rotate(180deg);
    }
  }
  .ant-collapse-item {
    margin-bottom: 4px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background-color: #fff;
  }
  .panel_checkbox {
    position: absolute;
    left: 24px;
  }
  .screen_con {
    height: 80px;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
  }
  .screen_name {
    width: 700px;
    height: 22px;
    // display: inline-block;
    padding-left: 16px;
    font-size: 14px;
    color: #3c3c3c;
    line-height: 22px;
    float: right;
  }
  .screen_info {
    width: 700px;
    height: 20px;
    // display: inline-block;
    padding-left: 16px;
    font-size: 12px;
    color: #aaa;
    line-height: 20px;
    float: right;
    margin-top: -20px;
    // position: absolute;
    // left: 161px;
    // margin-top: 28px;
  }
}
.bottom_con {
  position: absolute;
  height: 72px;
  width: 100%;
  bottom: 0;
  padding: 21px 40px 20px 40px;
  border-top: 1px solid #d8d8d8;
  .ant-checkbox-wrapper {
  }
  .bottom_count {
    display: inline-block;
    width: 80px;
    margin-left: 485px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #222;
  }
  .bottom_del {
    margin-left: 24px;
    border-radius: 4px;
  }
  .bottom_next {
    margin-left: 24px;
    background-color: #604bdc;
    border-color: #604bdc;
    color: #fff;
    border-radius: 4px;
    &:focus,
    &:hover,
    &:active {
      background-color: #7a67e4;
      border-color: #7a67e4;
    }
  }
}
</style>
<style lang="scss">
.rays_tuner_shader_mycart_shadertab {
  .ant-tabs-nav-wrap {
    height: 47px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    color: #3c3c3c;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: 500;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    font-weight: 500;
    color: #000;
  }
  .ant-tabs-ink-bar {
    background-color: #604bdc;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
}
#shader_mycart_shaderlist_scroll {
  .ant-collapse-header {
    padding: 20px 56px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .ant-collapse-extra {
    display: inline-block;
    float: none;
    margin-left: 16px;
  }
  .ant-collapse-content-box {
    padding: 0;
    .ant-image {
      margin-left: 16px;
    }
    img {
      border-radius: 4px;
      background-color: #222;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #604bdc;
    border-color: #604bdc;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #604bdc;
  }
  .ant-checkbox-checked::after {
    border-color: #604bdc;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #604bdc;
  }
}
.mycart_shaderlist_bottom_check {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #604bdc;
    border-color: #604bdc;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #604bdc;
  }
  .ant-checkbox-checked::after {
    border-color: #604bdc;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #604bdc;
  }
}
</style>
