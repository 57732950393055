import { webServiceApi, engineServiceApi } from '../service';

// 获取该画质检测任务frame-error图表结果
const getErrorDetectionChart = (params) => {
  return engineServiceApi.post(
    '/api/sopt/simplifier/getErrorDetectionChart',
    params
  );
};
// 获取该画质检测任务优化率和替换成功与否信息
const getErrorDetectionOptimizeInfo = (params) => {
  return engineServiceApi.post(
    '/api/sopt/simplifier/getErrorDetectionOptimizeInfo',
    params
  );
};

// 删除购物车接口
const cartDel = (params, config) => {
  return webServiceApi.post('/index.php/Cart/del', params, config);
};

// 提交画质检测任务
const addErrorDetectionTask = (params, config) => {
  return engineServiceApi.post(
    '/api/sopt/simplifier/addErrorDetectionTask',
    params,
    config
  );
};

// 获取购物车列表接口
const cartGets = (params) => {
  return webServiceApi.post('/index.php/Cart/gets', params);
};

// 下载画质检测shader
const downloadShader = (params, config) => {
  return engineServiceApi.post(
    '/api/sopt/simplifier/downloadShaders',
    params,
    config
  );
};
// 剔除部分shader后重新提交画质检测任务
const addDelErrorTask = (params) => {
  return engineServiceApi.post('/api/sopt/simplifier/addDeErrorTask', params);
};

/**
 * @description 优化报告详细信息
 * @name ReportDetail
 * ```js
 * {
 *   optimize: number;//项目优化率
 *   time: number; //节省时间
 *   error: number; //平均误差
 *   shader: number; //shader总数
 *   simplify: number; //已简化shader
 *   variant: number; //共生成变体
 *   filter: number; //共筛选变体
 *   check: number; //检测变体
 * }
 * ```
 */
export interface ReportDetail {
  optimize: number; //项目优化率
  time: number; //节省时间
  error: number; //平均误差
  shader: number; //shader总数
  simplify: number; //已简化shader
  variant: number; //共生成变体
  filter: number; //共筛选变体
  check: number; //检测变体
}

// 优化报告-获取用户下的所有项目名（目前以游戏包名为项目名）
const getPackagesOfUser = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getPackagesOfUser',
    params
  );
};

// 优化报告-获取优化结果页数据面板信息
const getOptimizationReportHeaderInfo = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getOptimizationReportHeaderInfo',
    params
  );
};

// 优化报告-获取项目下的所有shader简化结果
const getOptimizationResultInfoByFeatures = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getOptimizationResultInfoByFeatures',
    params
  );
};
// 优化报告-获取shader下所有已筛选变体列表
const getVariantListSelected = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getVariantListSelected',
    params
  );
};

// 优化报告-获取目标变体参与的所有画质检测任务
const getDetectionTasksOfVariant = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getDetectionTasksOfVariant',
    params
  );
};

// 优化报告-获取当前游戏包的所有pat
const getPatsOfPackage = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/getPatsOfPackage',
    params
  );
};

// 优化报告-设置/重置当前shader所选中的变体
const setAdnResetSelectedVariant = (params) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/setAdnResetSelectedVariant',
    params
  );
};

// 优化报告-批量下载
const batchFileDownload = (params, config) => {
  return engineServiceApi.post<any>(
    '/api/sopt/simplifier/batchFileDownload',
    params,
    config
  );
};

export default {
  getErrorDetectionChart: getErrorDetectionChart,
  getErrorDetectionOptimizeInfo: getErrorDetectionOptimizeInfo,
  cartDel: cartDel,
  addErrorDetectionTask: addErrorDetectionTask,
  cartGets: cartGets,
  downloadShader: downloadShader,
  addDelErrorTask: addDelErrorTask,
  getPackagesOfUser: getPackagesOfUser,
  getOptimizationReportHeaderInfo: getOptimizationReportHeaderInfo,
  getOptimizationResultInfoByFeatures: getOptimizationResultInfoByFeatures,
  getVariantListSelected: getVariantListSelected,
  getDetectionTasksOfVariant: getDetectionTasksOfVariant,
  getPatsOfPackage: getPatsOfPackage,
  setAdnResetSelectedVariant: setAdnResetSelectedVariant,
  batchFileDownload: batchFileDownload,
};

// get请求示例
const getExample = (params) => {
  return webServiceApi.get('/xxxx/xxx', { params });
};
