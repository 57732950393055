<template>
  <a-dropdown overlayClassName="rays_tuner_station">
    <a class="top-icon-con" @click.prevent>
      <a-badge :count="messageList.length">
        <svg-icon icon-class="bell" class="top-icon notice" />
      </a-badge>
    </a>
    <template #overlay>
      <a-menu v-if="showMessage" @click="clickMenu" class="message-menu">
        <div class="message-con">
          <DynamicScroller
            class="scroller"
            :items="messageList"
            :min-item-size="50"
            :item-height="450"
            key-field="id"
          >
            <template v-slot="{ item, active, index }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[item.title]"
                :data-index="index"
              >
                <a-menu-item class="menu-item" :key="item">
                  <div class="menu-item-con">
                    <span>{{
                      textFormat(item.title, item.type_id, item)
                    }}</span>
                  </div>
                  <div class="menu-item-time">
                    <span>{{ format(item.add_time) }}</span>
                  </div>
                </a-menu-item>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
          <!--                    <a-menu-item v-for="item in messageList" :key = item class="menu-item">-->
          <!--                        <div class="menu-item-con">-->
          <!--                            <span>{{textFormat(item.title,item.type_id,item)}}</span>-->
          <!--                        </div>-->
          <!--                        <div class="menu-item-time">-->
          <!--                            <span>{{format(item.add_time)}}</span>-->
          <!--                        </div>-->
          <!--                    </a-menu-item>-->
        </div>
        <div class="message_clear">
          站内信太多了？试试<span @click="warning" class="delete_all"
            >一键清空</span
          >
        </div>
      </a-menu>
      <a-menu class="message-menu" v-else>
        <div class="message_empty">暂无新消息</div>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  onUnmounted,
  createVNode,
} from 'vue';
import { message } from 'ant-design-vue';
import store from '@/vuex/store';
import {
  toShaderPage,
  toShaderSimplify,
  toShaderScreen,
} from '@/models/tunerStorage';
import router from '@/routes';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import api from '@/api/pat-manager';

export default defineComponent({
  name: 'StationMessage',
  components: {},
  setup() {
    let userId = store.state.user.userinfo;
    const messageList = ref(0);
    const showMessage = ref(true);

    const mailUpdate = computed(() => store.state.user.mailUpdate);

    watch(mailUpdate, () => {
      // console.log('mailUpdate watch');
      getMessageList();
    });

    const getMessageList = async () => {
      const params = {
        user_id: userId.uid,
      };
      const res = await api.messageGets(params);
      if (!res) return;
      if (res.code == 0) {
        showMessage.value = !!res.data;
        const _list = res.data || [];
        messageList.value = _list;
      } else {
        message.error('获取站内信列表总数失败');
      }
    };
    const getTaskList = async (taskId, title, typeId) => {
      const params = {
        task_id: taskId,
      };
      const res = await api.taskGet(params);
      if (!res) return;
      if (res.code == 0) {
        options(typeId, title, res.data);
      } else {
        message.error('获取单条任务失败!');
      }
    };

    const format = (time) => {
      let nowSec = new Date().getTime();
      let second = new Date(time.replace(/-/g, '/')).getTime();
      let timeDiff = (nowSec - second) / 1000;
      let nowYear = new Date().getFullYear();
      if (timeDiff >= 0 && timeDiff < 59) {
        return parseInt(timeDiff) + '秒前';
      } else if (timeDiff < 3600) {
        return parseInt(timeDiff / 60) + '分钟前';
      } else if (timeDiff < 3600 * 24) {
        return parseInt(timeDiff / 60 / 60) + '小时前';
      } else if (timeDiff < 3600 * 24 * 2) {
        return '昨天';
      } else if (timeDiff < 3600 * 24 * 7) {
        return parseInt(timeDiff / 60 / 60 / 24) + '天前';
      } else if (time.substring(0, 4) !== nowYear) {
        return time.substring(0, 10);
      } else {
        return time.substring(5, 5);
      }
    };
    const textFormat = (title, typeId) => {
      const typeArr = [
        null,
        '上传成功',
        '上传失败',
        '创建成功',
        '创建失败',
        '已完成',
        '被手动停止',
        '被系统中止',
      ];
      let titleCon = JSON.parse(title || '{}');
      let msg = null;
      const noti_code = titleCon.noti_code;
      if (typeId == 2) {
        msg = `简化任务${titleCon.task_number}`;
      } else if (typeId == 3) {
        msg = `筛选任务${titleCon.var_shader_name}`;
      } else if (typeId == 4) {
        msg = `画质监测任务${titleCon.task_number}`;
      } else if (typeId == 5) {
        msg = `APK(${titleCon.pat})转化任务`;
      }
      if ((noti_code == 1 || noti_code == 2) && typeId == 1) {
        msg = `PAT${titleCon.pat + typeArr[noti_code]}`;
      } else if (
        noti_code == 3 ||
        noti_code == 4 ||
        noti_code == 5 ||
        noti_code == 6 ||
        noti_code == 7
      ) {
        msg = typeId == 1 ? null : `${msg + typeArr[noti_code]}`;
      } else {
        msg = null;
      }
      return msg;
    };

    const warning = () => {
      Modal.confirm({
        title: () => '确定清空所有消息吗？',
        okText: () => '清空',
        okType: 'danger',
        icon: () => createVNode(ExclamationCircleOutlined),
        async onOk() {
          showMessage.value = false;
          const params = {
            user_id: userId.uid,
          };
          const res = await api.messageDelAll(params);
          if (!res) return;
          if (res.code == 0) {
            messageList.value = [];
            message.success('删除用户消息成功！');
          } else {
            message.error('删除用户消息失败!');
          }
        },
      });
    };

    const options = (typeid, title, taskList) => {
      let path;
      const computePath = (typeid) => {
        let res;
        if (typeid == '2') {
          res = '/mainhome/simplifyhome/simplifytask';
        } else if (typeid == '3') {
          res = '/mainhome/simplifyhome/screentask';
        } else if (typeid == '4') {
          res = '/mainhome/simplifyhome/imagequality';
        }
        return res;
      };
      switch (title.noti_code) {
        case 1:
          toShaderPage(title.pat);
          break;
        case 2:
          path = '/mainhome/simplifyhome/pat';
          break;
        case 3:
          path = computePath(typeid);
          break;
        case 4:
          break;
        case 5:
          if (typeid == '4') {
            path = `/imageQualityDetail/${title.pat}/${taskList.task_number}`;
          } else if (typeid == '2') {
            toShaderSimplify(
              title.pat,
              taskList.shader_id,
              taskList.task_number
            );
          } else if (typeid == '3') {
            toShaderScreen(
              title.pat,
              taskList.shader_id,
              taskList.parent_id,
              JSON.parse(taskList.params).simpl_task_id,
              taskList.task_number
            );
          }
          break;
        case 6:
          path = computePath(typeid);
          break;
        case 7:
          path = computePath(typeid);
          break;
        default:
          break;
      }
      path &&
        router.push({
          path,
        });
    };
    const clickMenu = ({ key }) => {
      let title = JSON.parse(key.title);
      let taskId = key.task_id;
      getTaskList(taskId, title, key.type_id);
    };

    let _times = null;
    const loop = () => {
      getMessageList();
      _times = setTimeout(() => {
        loop();
      }, 300000);
    };

    onMounted(() => {
      loop();
    });

    onUnmounted(() => {
      if (_times) {
        clearTimeout(_times);
      }
    });

    return {
      userId,
      messageList,
      showMessage,
      // showModal,
      format,
      textFormat,
      warning,
      clickMenu,
      options,
      // handleOk
    };
  },
});
</script>

<style scoped>
.top-icon-con {
  width: 48px;
  height: 47px;
  padding-left: 12px;
}
.top-icon-con:hover {
  background-color: #f0f0f0;
}
.top-icon {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  margin-right: 5px;
  color: #aaa;
  font-size: 20px;
}
.top-icon-con:hover .top-icon {
  color: #604bdc;
}
.top-icon-con:hover .top-icon.cart {
  color: #d8d8d8;
}
.message-menu {
  width: 320px;
  height: 504px;
}
.message-con {
  width: 320px;
  height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}
.scroller {
  height: 100%;
}
.message-menu :deep(.menu-item) {
  margin: 0 4px;
  border-radius: 4px;
}
.menu-item-con {
  width: 312px;
  white-space: normal;
  word-break: break-all;
  padding-right: 15px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #222222;
  line-height: 22px;
}
.menu-item-time {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #d8d8d8;
  line-height: 20px;
}
.message_clear {
  /*position: fixed;*/
  /*top: 487px;*/
  width: 320px;
  height: 54px;
  background-color: #fafafa;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 22px;
  padding: 16px 65px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: rgba(240, 240, 240, 1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(16, 15, 15, 0.5);
  background-color: rgba(16, 15, 15, 0.5);
}
.delete_all {
  color: #666666;
  padding-left: 7px;
  cursor: pointer;
  font-size: 14px;
}
.delete_all:hover {
  color: #ff2222;
}
.message_empty {
  width: 80px;
  height: 24px;
  margin: 240px 120px;
  color: #d8d8d8;
  font-size: 16px;
}
</style>
